import { defineStore } from 'pinia'
import type { Ref } from 'vue'

/*
  We do not annotate with types from the SDK, since the whole job of the store is to call a method and save the result.
  Basically, get, update, create and remove are any async functions, and getRequests is just an async function that returns them
*/
type Request = (...args: any[]) => Promise<any>
type GetRequests = () => Promise<{
  get: Request
  update?: Request
  create?: Request
  remove?: Request
}>

interface Options<GetReqs extends GetRequests = GetRequests> {
  getRequests: GetReqs
}

type Extending = Record<string, any> // we can extend a store in any way we want

const storeSetup = <GetReqs extends GetRequests>({
  getRequests
}: Options<GetReqs>) => {
  type ReceivedRequests = Awaited<ReturnType<GetReqs>>

  type ReceivedGet = ReceivedRequests['get']
  type ReceivedUpdate = ReceivedRequests['update']
  type ReceivedCreate = ReceivedRequests['create']
  type ReceivedRemove = ReceivedRequests['remove']

  type T = Awaited<ReturnType<ReceivedGet>>

  const data = ref(null) as Ref<T | null>
  const fetching = ref(false)
  const fetched = ref(false)

  const fetchData = async (...args: Parameters<ReceivedGet>): Promise<T> => {
    fetching.value = true

    try {
      const result = await getRequests().then(({ get }) => get(...args))
      data.value = result
      fetched.value = true

      return result
    } finally {
      fetching.value = false
    }
  }

  const fetchUpdate = async (
    ...args: ReceivedUpdate extends Request ? Parameters<ReceivedUpdate> : []
  ): Promise<T> => {
    const result = await getRequests().then(
      ({ update }) =>
        update?.(...args) ??
        Promise.reject(new Error('Method update is not implemented'))
    )

    data.value = result

    return result
  }

  const create = async (
    ...args: ReceivedCreate extends Request ? Parameters<ReceivedCreate> : []
  ): Promise<T> => {
    const result = await getRequests().then(
      ({ create }) =>
        create?.(...args) ??
        Promise.reject(new Error('Method create is not implemented'))
    )

    data.value = result

    return result
  }

  const remove = async (
    ...args: ReceivedRemove extends Request ? Parameters<ReceivedRemove> : []
  ) => {
    await getRequests().then(
      ({ remove }) =>
        remove?.(...args) ??
        Promise.reject(new Error('Method remove is not implemented'))
    )

    data.value = null
  }

  const updateData = (updates: Partial<T>) => {
    if (data.value) {
      data.value = Object.assign({}, data.value, updates)
    }
  }

  const flush = () => {
    data.value = null
    fetched.value = false
  }

  return {
    data,
    fetching,
    fetched,
    fetchData,
    fetchUpdate,
    create,
    remove,
    updateData,
    flush
  }
}

export const createSimpleStore = <
  GetReqs extends GetRequests,
  E extends Extending = {}
>(
  key: string,
  options: Options<GetReqs>,
  setup?: (context: ReturnType<typeof storeSetup<GetReqs>>) => E
) =>
  defineStore(key, () => {
    const context = storeSetup(options)
    const extending = Object.assign({}, setup?.(context))

    return { ...context, ...extending }
  })
