<template>
  <div :class="$style.container">
    <div :class="$style.spinner" :style="style"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Theme } from './consts'

defineOptions({
  name: 'UiSpinner'
})

const $props = withDefaults(
  defineProps<{
    theme?: Theme
    size?: string | number
  }>(),
  {
    theme: 'accent',
    size: 4
  }
)

const color = computed(() => {
  switch ($props.theme) {
    case 'accent':
      return '#00C7B1'
    case 'primary':
      return '#0A0E29'
    case 'light':
      return '#ffffff'
    case 'current':
      return 'currentColor'
    default:
      return 'rgba(255, 255, 255, 0.3)'
  }
})

const style = computed(() => ({
  width: $props.size + 'em',
  height: $props.size + 'em',
  borderTop: `0.2em solid ${color.value}`,
  borderRight: `0.2em solid ${color.value}`,
  borderBottom: `0.2em solid ${color.value}`
}))
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  /* Prevent resizing */
  overflow: hidden;
  flex-shrink: 0; // not to be affected from the outside
}

.spinner {
  border-radius: 50%;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinner 0.4s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
