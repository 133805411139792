const host = 'pass.pari.ru'
const projectPath = ''
export default {
  host,

  name: 'PARIPASS',

  maintenance: false,
  endOfMaintenance: 0,

  basePath: projectPath,
  baseURL: `https://${host}${projectPath}/`,

  seasonActive: true,
  startSeasonDate: new Date('2024-10-09T11:00:00.000+03:00').getTime(),

  defaultLocale: 'ru',

  shareImageUrl: `https://${host}/static/images/share.jpg`,
  siteName: 'PARI',
  ogTitle: 'PARI Pass - Выигрывай призы от PARI',
  ogDescription:
    'Выполняй задания, находи спрятанные коды и получай крутые призы. Не упусти возможность получить фрибеты и другие крутые подарки от PARI.',

  analytics: {
    gtmId: 'GTM-MNL6C3X',
    amplitudeId: '27e0cbb1ad8fdc336b673ba1ba9b6596'
  },

  auth: {
    redirect: '/',
    callbackPath: `${projectPath}/callback/`
  },
  mainCoinCode: 'pari',
  supportUrl: 'https://t.me/paripass_support_user_bot',
  shopGuideUrl: 'https://vk.com/@pari_pass-gaid-po-magazinu',
  pariBonusesUrl: 'https://www.pari.ru/account/bonuses-and-actions'
}
